@import "../Admin/index.module.scss";

$panel-horizontal-padding: 25px;

.Panel button {
  appearance: none;
  background: none;
  color: rgba(black, 0.7);
  border: solid 1px currentColor;
  border-radius: 6px;
  padding: 5px 10px;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;

  &.IconOnlyButton {
    border: none;
    display: flex;
    padding: 0;
  }
}

.Panel {
  background: white;
  color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  padding: 70px 0 0;
  overflow-y: auto;
  z-index: 9;

  > header {
    padding: 0 $panel-horizontal-padding;

    h2 {
      font-size: 24px;
      font-weight: $font-weight-semibold;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
    }

    .Close {
      position: absolute;
      right: $panel-horizontal-padding;
      top: $panel-horizontal-padding;
    }
  }
}

.Day {
  padding: 0 $panel-horizontal-padding;
  $border: 1px solid rgba(black, 0.1);
  border-top: $border;
  &:last-of-type {
    border-bottom: $border;
  }

  > header {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    // Disable selection as this is clickable, and rapid clicking causes the
    // entire section to become selected
    user-select: none;

    h3 {
      font-size: 16px;
      font-weight: $font-weight-semibold;
    }

    .ExpandCollapse {
      svg {
        transform: scaleY(-1);
      }
      &.Expanded svg {
        transform: unset;
      }
    }
  }
}

.Question {
  padding-left: 10px;
  padding-bottom: 20px;

  h4 {
    font-size: 16px;
    font-weight: $font-weight-semibold;
  }

  .Comments {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .Empty {
    margin-top: 7px;
    font-style: italic;
    opacity: 0.5;
  }

  .Comment {
    background: rgba(black, 0.04);
    padding: 15px;
    border-radius: 8px;
    margin-top: 10px;
    position: relative;

    .ReadState {
      position: absolute;
      top: 15px;
      right: 15px;

      svg path,
      svg circle {
        fill: currentColor;
      }
    }

    .Author {
      font-style: normal;
      font-weight: $font-weight-semibold;
      margin-bottom: 5px;
    }

    .Text {
      margin-bottom: 0;
    }
  }
}

.AddCommentForm {
  display: grid;
  gap: 10px;

  input,
  textarea {
    appearance: none;
    background: none;
    color: black;
    border: solid 1px rgba(black, 0.7);
    border-radius: 6px;
    padding: 10px 15px;
    font: inherit;
    font-size: 14px;
    resize: vertical;
  }

  .Buttons {
    display: flex;

    :first-child {
      margin-right: 10px;
    }
  }
}

.CommentActions {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
