@use "sass:math";
@import "../../constants";

.Toolbar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  margin-top: 15px;
  @include min-width(large) {
    margin-top: 0;
    align-self: flex-end;
  }

  > button {
    $padding: 9px;
    padding: $padding;
    margin-right: 10px;
    appearance: none;
    background: none;
    border: none;
    font-size: 11px;
    color: $toolbar-color;
    cursor: pointer;

    > div {
      // Container to center button's icon
      display: grid;
      height: 20px;
      place-content: center;
      margin-bottom: 5px;

      svg path {
        fill: currentColor;
      }
    }

    &.HasUnreadComments > div {
      position: relative;

      &::before {
        content: "";
        $dotSize: 8px;
        width: $dotSize;
        height: $dotSize;
        background: currentColor;
        border-radius: 100%;
        position: absolute;
        right: 3px;
        top: -2px;
      }
    }

    &.Active {
      background: rgba($toolbar-color, 0.1);
      border-radius: 12px;
    }

    &:last-of-type {
      margin-right: 0;
      @include min-width(large) {
        // Since the button has no border, compensate the padding to make the
        // the last button perfectly align with the edge of the container
        margin-right: -$padding;
      }
    }

    &.Logout {
      display: none;
      @include min-width(large) {
        display: block;
      }
    }
  }
}
