@import "../../constants";

.AuthFormError {
  margin-top: 30px;
}

.AuthResetSuccess {
  font-size: 20px;
  line-height: 28px;
}
