@import "../../constants";

.Page {
  min-height: 100%;

  @include max-width(large) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @include min-width(large) {
    display: grid;
    grid-template-columns: $progress-bar-width auto 1fr;
    overflow-y: auto;
  }
}

.PageDay {
  &1 {
    --day-theme-color-text: #{$day-theme-color-1-text};
    --day-theme-color-dark: #{$day-theme-color-1-dark};
    --day-theme-bg-opacity: #{$day-theme-color-1-bg-opacity};
  }

  &2 {
    --day-theme-color-text: #{$day-theme-color-2-text};
    --day-theme-color-dark: #{$day-theme-color-2-dark};
    --day-theme-bg-opacity: #{$day-theme-color-2-bg-opacity};
  }

  &3 {
    --day-theme-color-dark: #{$day-theme-color-3-dark};
    --day-theme-color-text: #{$day-theme-color-3-text};
    --day-theme-bg-opacity: #{$day-theme-color-3-bg-opacity};
  }

  &4 {
    --day-theme-color-text: #{$day-theme-color-4-text};
    --day-theme-color-dark: #{$day-theme-color-4-dark};
    --day-theme-bg-opacity: #{$day-theme-color-4-bg-opacity};
  }

  &5 {
    --day-theme-color-text: #{$day-theme-color-5-text};
    --day-theme-color-dark: #{$day-theme-color-5-dark};
    --day-theme-bg-opacity: #{$day-theme-color-5-bg-opacity};
  }
}
