@use "sass:math";

// Base Variables
$base-font-family: "DWD Body", -apple-system, sans-serif;
$alt-font-family: "DWD Alt", -apple-system, sans-serif;
$heading-font-family: "RRI Heading", -apple-system, sans-serif;
$base-spacing-unit: 28px;

$transition-duration-fast: 125ms;
@value transitionDurationFast: #{math.div($transition-duration-fast, 1ms)};

$transition: 200ms ease-in-out;
$transition-fast: $transition-duration-fast ease-in-out;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 800;

$default-line-height: 1.3;

$button-radius: 16px;

$white: #ffffff;
$black: #000000;
$lighter-gray: #dadada;
// $light-gray: somwhere between $lighter and $mid
$mid-gray: #565454;
$dark-gray: #292929;

$color-default: #14aecf;
$color-error: #e24628;

// Day Theme Colors

// * ui – 3:1 against white
// * text – 4.5:1 against white
// * dark – 4.5:1 against theme-color

$day-theme-default: $dark-gray;

$day-theme-color-1: #14aecf;
$day-theme-color-1-text: #1a80a2;
$day-theme-color-1-dark: #0d597b;
$day-theme-color-1-bg-opacity: 0.4;

$day-theme-color-2: #9ecb3d;
$day-theme-color-2-text: #3f851c;
$day-theme-color-2-dark: #1f6504;
$day-theme-color-2-bg-opacity: 0.4;

$day-theme-color-3: #faa41b;
$day-theme-color-3-text: #b55f2a;
$day-theme-color-3-dark: #943e09;
$day-theme-color-3-bg-opacity: 0.4;

$day-theme-color-4: #f73913;
$day-theme-color-4-text: #d82706;
$day-theme-color-4-dark: #b10d31;
$day-theme-color-4-bg-opacity: 0.4;

$day-theme-color-5: #aa2173;
$day-theme-color-5-text: #c51c81;
$day-theme-color-5-dark: #a90065;
$day-theme-color-5-bg-opacity: 0.4;

$toolbar-color: #0d192d;

// Page Layout
$left-panel-padding: 24px;
$right-panel-padding: 48px;
$mobile-panel-padding: 32px;

// Input
$input-max-width: 592px;
$input-wide-max-width: 720px;
$input-min-height: 70px;
$input-bottom-border-width: 2px;
$input-label-font-weight: $font-weight-semibold;
$input-label-font-size: 20px;
$input-label-line-height: 24px;

// Progress Bar
$progress-bar-background: #0d192d;
$progress-bar-width: 160px;

// UI
$header-height: 123px;

$bp-pixels-map: (
  small: 600,
  medium: 760,
  large: 1024,
  xlarge: 1260,
);

// Admin
$admin-background: #081024;
$admin-color: white;
$admin-accent: #ff5c00;

@mixin max-width($bp-or-em) {
  $max-px: if(
    type-of($bp-or-em) == string,
    map-get($bp-pixels-map, $bp-or-em),
    $bp-or-em
  );
  $max-em: math.div($max-px - 1, 16) * 1em;

  @media only screen and (max-width: #{$max-em}) {
    @content;
  }
}

@mixin min-width($bp-or-em) {
  $min-px: if(
    type-of($bp-or-em) == string,
    map-get($bp-pixels-map, $bp-or-em),
    $bp-or-em
  );
  $min-em: math.div($min-px, 16) * 1em;

  @media only screen and (min-width: #{$min-em}) {
    @content;
  }
}

@mixin min-max-width($min-bp-or-em, $max-bp-or-em) {
  $min-px: if(
    type-of($min-bp-or-em) == string,
    map-get($bp-pixels-map, $min-bp-or-em),
    $min-bp-or-em
  );
  $min-em: math.div($min-px, 16) * 1em;

  $max-px: if(
    type-of($max-bp-or-em) == string,
    map-get($bp-pixels-map, $max-bp-or-em),
    $max-bp-or-em
  );
  $max-em: math.div($max-px - 1, 16) * 1em;

  @media only screen and (min-width: #{$min-em}) and (max-width: #{$max-em}) {
    @content;
  }
}
