@use "sass:math";
@import "../../constants";

$quiz-sidebar-mini-poster-height: 120px;

.QuizQuestionSidebarDescription {
  margin: 0;

  & + & {
    display: block;
    margin-top: 16px;
  }
}

$quiz-sidebar-bullet-width: 8px;
$quiz-sidebar-bullet-indent: 32px;

.QuizQuestionSidebarList {
  padding-left: $quiz-sidebar-bullet-indent;
  font-size: 20px;
  margin-top: 0.5em;

  li {
    list-style: none;
    display: flex;
    position: relative;
    margin-bottom: 0.25em;

    &::before {
      content: "";
      background: currentColor;
      width: $quiz-sidebar-bullet-indent;
      text-align: center;
      position: absolute;
      height: $quiz-sidebar-bullet-width;
      width: $quiz-sidebar-bullet-width;
      margin: math.div($quiz-sidebar-bullet-width, -2) 0 0
        math.div($quiz-sidebar-bullet-width, -2);
      border-radius: 50%;
      left: math.div($quiz-sidebar-bullet-indent, -2);
      top: $default-line-height * 0.5em; // Half of line-height
    }
  }
}

.QuizQuestionSidebarNextButton {
  margin-right: auto;
  margin-top: 16px;
}

.QuizQuestionRightPanel {
  // Make so the right panel has the same top/bottom margin of the left panel,
  // so things are aligned
  padding: $left-panel-padding 0;
  @include min-width(large) {
    overflow-y: hidden;
  }

  form {
    flex: 1;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    // With justify-content: center, if the child is larger than the parent it
    // gets centered but it overflows the parent's boundaries. With this
    // space-between configuration instead it gets centered when it's smaller,
    // while reverting to a flex-start like appearance when it's bigger.
    justify-content: space-between;
    &::before {
      content: "";
    }
    &::after {
      content: "";
    }

    .Fields {
      padding: 0 $mobile-panel-padding;
      // Define a min-height for the field wrapper that takes into account the
      // Submit button, so the field doesn't move vertically when it's
      // hidden/shown
      min-height: 300px;
      @include min-width(large) {
        padding: 0 $right-panel-padding;
      }

      &.Sortable {
        // Since the sortable contains the steps navigation bar too we can't
        // center it vertically here. Let's just give him all the space
        // available, and it will take care of properly place its children
        padding: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
      }
    }
  }
}

.QuizQuestionTopArea {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0 $mobile-panel-padding 40px;
  @include min-width(large) {
    padding: 0 $right-panel-padding 10px;
    flex-direction: column;
  }
}

.QuizQuestionsContainer {
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  &::before {
    content: "";
  }
  &::after {
    content: "";
  }
  @include min-width(large) {
    justify-content: flex-start;
  }
}

.QuizQuestionsNav {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 35px;
  background: $white;
  @include min-width(large) {
    margin-top: 25px;
  }

  a {
    text-decoration: none;
    padding: 5px 4px;
    border-bottom: solid 3px transparent;
    font-size: 18px;
    font-weight: $font-weight-semibold;
    color: $mid-gray;

    &.forbidNavigation {
      cursor: not-allowed;
    }

    &[aria-disabled="true"] {
      opacity: 0.5;
      pointer-events: none;
    }

    &[aria-current="page"] {
      color: $black;
      border-bottom-color: var(--day-theme-color-text, currentColor);
      cursor: default;
    }
  }
}

.MiniPoster {
  color: $mid-gray;
  height: $quiz-sidebar-mini-poster-height;
  max-width: $quiz-sidebar-mini-poster-height * 1.5;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.MiniPosterTitle {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  font-weight: $font-weight-bold;
}

.MiniPosterGrid {
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 3fr 8fr 6fr;
  grid-gap: 4px;

  > span {
    background: var(--day-theme-color-text);
    border-radius: 4px;
    opacity: 0.4;
  }

  > :first-child {
    grid-column: 1 / -1;
  }

  > .MiniPosterActiveCell {
    opacity: 1;
  }
}
