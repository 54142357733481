@import "../../constants.scss";

.RefreshButton {
  margin-left: 20px;
  height: 49px; // Keeps height fixed when the button switches to the loading spinner
  padding: 10px;
  background: none;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: flex;
  place-items: center;

  svg path {
    fill: currentColor;
  }
}

.Spinner {
  $size: 24px;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 3px solid;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
