@use "sass:math";
@import "../../constants";

.ErrorMessage {
  color: $mid-gray;
  display: flex;
  margin-top: math.div($base-spacing-unit, 2);
  max-width: $input-max-width;
}

.ErrorMessageIcon {
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  fill: $color-error;
  margin-right: math.div($base-spacing-unit, 2.5);
}

.ErrorMessageText {
  font-weight: $font-weight-semibold;
  margin-bottom: 0;

  a,
  button {
    color: var(--day-theme-color-text, currentColor);
    text-decoration: underline;
    transition: color $transition;

    &:hover,
    &:focus {
      color: var(--day-theme-color-dark, currentColor);
    }
  }

  button {
    apperance: none;
    outline: 0;
    font: inherit;
    border: 0;
    cursor: pointer;
    background: none;
  }
}
