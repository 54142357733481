@use "sass:math";
@import "../../constants";

.LeftPanel {
  --left-panel-text-opacity: 0.8;
  --left-panel-quote-opacity: 0.6;

  background: rgba($white, 0.75);
  color: $black;
  display: flex;
  flex-direction: column;
  margin: math.div($mobile-panel-padding, 2);
  border: 1px solid $lighter-gray;
  border-radius: 20px;
  position: relative;
  overflow: hidden;

  @include min-width(large) {
    flex: 1;
    width: 33vw;
    max-width: 496px;
    margin: $left-panel-padding;
  }
}

.LeftPanelReversed {
  --left-panel-text-opacity: 0.7;
  --left-panel-quote-opacity: 0.4;

  background: none;
  border-color: transparent;
  color: $white;
}

.LeftPanelWrapper {
  display: flex;
  flex-direction: column;
  padding: $left-panel-padding math.div($mobile-panel-padding, 2);
  height: 100%;

  @include min-width(large) {
    mask: linear-gradient(
      rgba(0, 0, 0, 0),
      black $left-panel-padding,
      black calc(100% - #{$left-panel-padding}),
      rgba(0, 0, 0, 0) 100%
    );
    padding-left: $left-panel-padding;
    padding-right: $left-panel-padding;
    overflow-y: auto;
  }
}

.LeftPanelTitle {
  font-weight: $font-weight-regular;
  font-size: 40px;
  margin-bottom: 24px;
  line-height: 1;
  margin-bottom: math.div($base-spacing-unit, 2);

  strong {
    font-weight: $font-weight-bold;
  }

  @include min-width(large) {
    font-size: 44px;
  }
}

.LeftPanelDescription {
  font-size: 18px;
  font-weight: $font-weight-semibold;
  opacity: var(--left-panel-text-opacity);
  max-width: 32em;

  .LeftPanelReversed & {
    opacity: var(--left-panel-text-opacity);
  }

  @include min-width(large) {
    font-size: 22px;
    max-width: 20em;
    margin-top: 0.5em;
  }

  p {
    margin-bottom: 0.5em;
  }
}

.LeftPanelQuote {
  font-size: 24px;
  font-style: italic;
  font-weight: $font-weight-semibold;
  text-indent: -0.4em;
  max-width: 24em;

  p {
    color: rgba($black, var(--left-panel-quote-opacity));
    margin-bottom: 0;

    .LeftPanelReversed & {
      color: rgba($white, var(--left-panel-quote-opacity));
    }
  }

  cite {
    color: rgba($black, var(--left-panel-text-opacity));
    margin-top: 10px;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-regular;
    text-indent: 0;

    &::before {
      content: "— ";
    }

    .LeftPanelReversed & {
      color: rgba($white, var(--left-panel-text-opacity));
    }
  }
}

.LeftPanelFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  padding-top: 32px;
}

.LeftPanelCopyright {
  font-size: 12px;
  margin-bottom: -4px;
  display: flex;
  align-items: flex-end;
  line-height: 16px;
  opacity: var(--left-panel-text-opacity);
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
}
