@import "../../constants.scss";

$button-color: $white;

.Button {
  // Reset
  border: 0;
  box-shadow: none;
  border-radius: 0;
  font-family: inherit;
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  // Styles
  background-color: var(--day-theme-color-text, $color-default);
  border-radius: $button-radius;
  font-size: 16px;
  font-weight: $font-weight-semibold;
  color: $button-color;
  min-height: 52px;
  line-height: 22px;
  padding: 0 $base-spacing-unit 1px;
  letter-spacing: 0.01em;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color $transition, color $transition-fast,
    transform $transition;
  outline: none;
  text-align: center;

  &[disabled]:not(.ButtonLoading) {
    background-color: darken($lighter-gray, 20%);
    cursor: not-allowed;
  }

  &:active:not([disabled]),
  &:hover:not([disabled]),
  &:focus {
    background-color: var(--day-theme-color-dark, darken($color-default, 5%));
  }

  &:active:not([disabled]) {
    transform: scale(0.975);
  }

  @keyframes rotate-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .ButtonSpinner {
    width: 24px;
  }
}

.ButtonLoading {
  color: rgba($button-color, 0);
  cursor: progress;
}
