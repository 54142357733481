@import "../../constants.scss";

.Progress {
  display: block;
  margin: auto;
  fill: none;

  circle {
    stroke: var(--circle-bg, #414655);
  }

  circle + circle {
    stroke: var(--circle-stroke, $admin-accent);
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
  }

  text {
    fill: currentColor;
    font-size: 25px;
    font-weight: $font-weight-semibold;
  }
}
