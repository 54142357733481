@use "sass:math";
@import "../../constants.scss";

$input-number-width: 24px;
$input-number-gutter: 16px;

@value inputBottomBorderWidth: #{math.div($input-bottom-border-width, 1px)}; // Removes the unit

.Input {
  color: $mid-gray;
  display: block;
  margin-bottom: 48px;
  max-width: $input-max-width;
  position: relative;

  &:valid {
    background: green;
  }
}

.InputTextareaShort {
  --textarea-max-rows: 4;
}

.InputTextareaLong {
  --textarea-max-rows: 8;

  @media screen and (min-height: #{math.div(760, 16) * 1em}) {
    --textarea-max-rows: 12;
  }

  @media screen and (min-height: #{math.div(840, 16) * 1em}) {
    --textarea-max-rows: 16;
  }
}

.InputWide {
  max-width: $input-wide-max-width;
}

.InputLabel,
.InputField {
  display: block;
  width: 100%;
}

.InputLabel {
  display: flex;
  font-weight: $input-label-font-weight;
  font-size: $input-label-font-size;
  line-height: $input-label-line-height;
  transition: color $transition;
  word-break: break-word;
  position: relative;
  z-index: 1; // Puts the label over the input so the label shows through

  .Input:not(.InputReadonly):focus-within > & {
    color: var(--day-theme-color-text, currentColor);
  }

  .InputHasNumber & {
    padding-left: #{$input-number-width + $input-number-gutter};
  }
}

.InputLabelText {
  flex-grow: 1;
  margin-right: 16px;
}

.InputLabelHelper {
  color: rgba($mid-gray, 0.8);
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
  white-space: nowrap;

  abbr {
    text-decoration: none;
    border: 0;
  }

  a {
    color: var(--day-theme-color-text, currentColor);
    text-decoration: underline;
    transition: color $transition;

    &:hover,
    &:focus {
      color: var(--day-theme-color-dark, currentColor);
    }
  }
}

.InputWrapperOuter {
  position: relative;
  display: flex;
  align-items: center;
  min-height: $input-min-height;
  width: 100%;
}

.InputWrapperInner {
  position: relative;
  width: 100%;
}

.InputNumber {
  color: currentColor;
  border-radius: 50%;
  opacity: 0.65; // 3:1 ratio against white
  font-weight: $font-weight-semibold;
  font-size: 24px;
  line-height: $input-min-height - 1px; // Vertically centers
  text-align: center;
  width: $input-number-width;
  margin-right: 16px;
  padding-right: 1px; // Optically centers the text in the circle
  flex-shrink: 0;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 2;
}

.InputField {
  font-size: 28px;
  font-family: inherit;
  border: 0;
  box-shadow: none;
  appearance: none;
  border-radius: 0;
  outline: none;
  color: $black;
  font-weight: $font-weight-semibold;
  border-bottom: $input-bottom-border-width solid rgba($mid-gray, 0.16);
  line-height: $input-min-height - $input-bottom-border-width;
  transition: $transition;
  transition-property: border-color, color;
  padding: 0;
  padding-right: var(--input-padding-right, 0);

  &::placeholder {
    color: $mid-gray;
    opacity: 0.63; // It's 40% in the designs, but 63% here to make it accessible
    font-weight: $font-weight-regular;
  }

  .InputHasNumber & {
    padding-left: #{$input-number-width + $input-number-gutter};
  }

  .InputReadonly & {
    cursor: default;
  }

  .Input:not(.InputReadonly) & {
    &:hover {
      border-color: rgba($mid-gray, 0.32);
    }

    &:focus {
      border-color: var(--day-theme-color-text, currentColor);
    }

    &.InputFieldInvalid {
      border-color: $color-error;
      color: $color-error;
    }
  }
}

.InputFieldTextareaShort,
.InputFieldTextareaLong {
  --textarea-line-height: 40px;
  --textarea-padding: 14px;

  box-sizing: border-box;
  line-height: var(--textarea-line-height);
  padding-top: var(--textarea-padding);
  padding-bottom: var(--textarea-padding);
  min-height: calc(
    var(--textarea-line-height) + #{$input-bottom-border-width} + (var(
            --textarea-padding
          ) * 2)
  );
  max-height: calc(
    (var(--textarea-line-height) * var(--textarea-max-rows)) + #{$input-bottom-border-width} +
      (var(--textarea-padding) * 2)
  );
  padding-right: calc(
    var(--input-padding-right, 0px) + 32px
  ); // Creates space for the counter
  resize: none;
}

.InputFieldTextareaLong {
  --textarea-line-height: 28px;
  --textarea-padding: 10px;

  margin-top: -4px; // Rebalances the space to the label given the extra padding
  font-size: 20px;
  font-weight: $font-weight-semibold;
}

.InputFieldPassword {
  padding-right: 60px;
}

.InputPasswordToggle {
  appearance: none;
  background: none;
  border: 0;
  outline: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: math.div(40px, -2);
  right: 0;
  opacity: 0.4;
  transition: opacity $transition;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  path {
    fill: currentColor;
  }
}

.InputCounter {
  font-weight: $font-weight-semibold;
  pointer-events: none;
  position: absolute;
  bottom: 4px;
  right: var(--input-padding-right, 0);
  font-size: 14px;
  display: flex;
  color: rgba($mid-gray, 0.7);
  opacity: 0;
  transition: opacity $transition;

  // Fake tabular numbers
  > span {
    width: 0.55em;
    text-align: center;
  }

  .InputField:focus ~ & {
    opacity: 1;
  }
}

.InputCounterError {
  color: $color-error;
}

.InputError {
  color: $mid-gray;
  transition: color $transition;

  .Input:not(.InputReadonly):focus-within > & {
    color: $color-error;
  }
}
