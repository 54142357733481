@import "../../constants";

@value theme1BgColor: #{$day-theme-color-1-text};
@value theme2BgColor: #{$day-theme-color-2-text};
@value theme3BgColor: #{$day-theme-color-3-text};
@value theme4BgColor: #{$day-theme-color-4-text};
@value theme5BgColor: #{$day-theme-color-5-text};

@value theme1BgOpacity: #{$day-theme-color-1-bg-opacity};
@value theme2BgOpacity: #{$day-theme-color-2-bg-opacity};
@value theme3BgOpacity: #{$day-theme-color-3-bg-opacity};
@value theme4BgOpacity: #{$day-theme-color-4-bg-opacity};
@value theme5BgOpacity: #{$day-theme-color-5-bg-opacity};

$header-toolbar-height: 96px;

.PosterPage {
  display: flex;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: span 2;
  padding-top: $header-toolbar-height;

  &1 {
    --day-theme-color-text: #{$day-theme-color-1-text};
    --day-theme-color-dark: #{$day-theme-color-1-dark};
  }

  &2 {
    --day-theme-color-text: #{$day-theme-color-2-text};
    --day-theme-color-dark: #{$day-theme-color-2-dark};
  }

  &3 {
    --day-theme-color-text: #{$day-theme-color-3-text};
    --day-theme-color-dark: #{$day-theme-color-3-dark};
  }

  &4 {
    --day-theme-color-text: #{$day-theme-color-4-text};
    --day-theme-color-dark: #{$day-theme-color-4-dark};
  }

  &5 {
    --day-theme-color-text: #{$day-theme-color-5-text};
    --day-theme-color-dark: #{$day-theme-color-5-dark};
  }
}

.PosterHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  padding: 0 16px;
  min-height: $header-toolbar-height;
  background-color: rgba($white, 0.9);
}

.CopySharedLinkButtonContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
}

.CopySharedLinkButton {
  border: 1px solid #ccc;
  padding: 6px 24px;
  border-radius: 18px;
  margin-left: 18px;
}

.SharedPosterLink {
  margin-right: 24px;
  color: var(--day-theme-color-text);
  font-size: 18px;
}

.ShareButton {
  margin-right: 24px;
}

.PosterTitle {
  margin-left: 24px;
}

.PosterLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: white;
}
