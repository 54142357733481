@import "../../constants";

.Admin {
  background: $admin-background;
  color: $admin-color;
  font-size: 17px;
  flex: 1 1;
}

.SearchCancel {
  appearance: none;
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;

  svg path {
    fill: currentColor;
  }
}

.FlaggedMark {
  $size: 22px;
  display: inline-block;
  border-radius: 100%;
  background: $admin-accent;
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  margin-left: 20px;
  font-weight: $font-weight-bold;
  user-select: none;
}

.Logo {
  width: 114px;
  height: 123px;
}

.Search {
  position: relative;
  top: -4px;
  margin-right: 45px;
  display: flex;
  align-items: center;

  svg path {
    fill: currentColor;
  }

  .SearchIcon {
    margin-right: 10px;
    margin-top: 2px;
  }

  .SearchContainer {
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 5px;
      right: 5px;
      bottom: -2px;
      border-bottom: solid currentColor 2px;
    }
  }

  .SearchCancel {
    $padding: 7px;
    padding: $padding;
    position: absolute;
    right: $padding * -1 + 5px;
    top: 4px;
    appearance: none;
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
  }

  input {
    background: none;
    border: none;
    color: currentColor;
    font-size: inherit;
    line-height: 2;
    font-weight: inherit;
    font-family: inherit;
    padding-right: 20px;
    padding-left: 5px;
  }
}

.FlaggedCount {
  margin-right: 40px;
  display: flex;
  align-items: baseline;

  span {
    $size: 30px;
    border-radius: 100%;
    background: $admin-accent;
    width: $size;
    height: $size;
    line-height: $size;
    text-align: center;
    margin-right: 13px;
  }
}

.TitleContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;

  h1 {
    font-weight: $font-weight-regular;
    font-size: 45px;
    margin-left: 30px;

    span {
      font-weight: $font-weight-semibold;
    }

    .SearchCancel {
      margin-left: 20px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.Pages {
  display: flex;
  align-items: center;
  padding-right: 30px;
  position: relative;
  top: 5px; // Align with title baseline on the left

  button,
  span {
    user-select: none;
    opacity: 0.5;
    padding: 10px;
    background: none;
    border: none;
    color: currentColor;
    font-size: inherit;
    font-weight: $font-weight-semibold;
    font-family: inherit;
    cursor: pointer;
    display: flex;

    svg path {
      fill: currentColor;
    }

    &:disabled {
      cursor: default;
    }

    &.PageCurrent {
      opacity: 1;
    }
  }

  span {
    cursor: default;
  }

  .RefreshButton {
    opacity: 1;
  }
}

.Loading {
  display: block;
  text-align: center;
  font-size: 26px;
  margin-top: 40px;
}

.ClientsTable {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  padding-bottom: 70px;

  td,
  th {
    border-bottom: solid 3px $admin-background;
    text-align: center;
    vertical-align: middle;
    height: 55px;
    padding: 0 10px;
    white-space: nowrap;

    &:first-child {
      padding-left: 30px;
      text-align: left;
      max-width: 60%;
    }

    a {
      color: currentColor;
      text-decoration: none;
      display: block;

      .Name {
        display: inline-block;
        vertical-align: top;
        max-width: 40vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  tbody tr {
    background: rgba(white, 0.1);
    transition: background 150ms ease-out;

    &.Flagged {
      background: rgba(white, 0.15);
    }
  }
}

.Switch {
  $padding: 2px;
  appearance: none;
  display: block;
  margin: auto;
  position: relative;
  background: $admin-background;
  height: 26px;
  width: 50px;
  border: solid 1px #25304e;
  border-radius: 26px;
  cursor: pointer;

  &::before {
    $size: 20px;
    content: "";
    position: absolute;
    top: $padding;
    left: calc(100% - #{$size} - #{$padding});
    width: $size;
    height: $size;
    background: #414655;
    border-radius: 100%;
    transition: 150ms ease-out;
    transition-property: background, left;
  }

  &:checked::before {
    background: $admin-accent;
    left: $padding;
  }
}

.Email {
  font-size: 14px;
  opacity: 0.6;
}
