@use "sass:math";
@import "../../constants";

.AuthFormError {
  margin-top: 30px;
}

.AuthFormName {
  @include min-width(xlarge) {
    display: flex;
    align-items: flex-start;
  }
}

.AuthFormNameInput {
  @include min-width(xlarge) {
    width: calc(50% - #{math.div(20px, 2)});

    > span {
      width: 200%;
    }
  }

  &:nth-of-type(1) {
    margin-bottom: 20px;
  }

  &:nth-of-type(2) {
    // Hides the second label from not-screenreader users
    > span {
      visibility: hidden;
      white-space: nowrap;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    @include min-width(xlarge) {
      margin-left: 20px;

      // Makes the invisible space the same height as the other input to ensure that it
      // matches up next to each other
      > span {
        height: auto;
      }
    }
  }
}
