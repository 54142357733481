@use "sass:math";
@import "../../constants";

$progress-bar-unstacked-height: 80px;

.Sidebar {
  display: flex;

  &.HomeSidebar {
    background: $black;
  }

  &.ProgressSidebar {
    position: relative;

    background-color: var(--day-theme-color-text);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-image: url("../../images/poly-background.svg");
      mix-blend-mode: multiply;
      opacity: var(--day-theme-bg-opacity);
      background-position: center;
      background-size: cover;
    }
  }
}

.Scrollable {
  z-index: 1;
  display: flex;
  align-items: center;
  overflow-x: auto;

  @include max-width(large) {
    // Hack to allow the overflow-x yo work for whatever reason
    &::after {
      content: "";
      width: 16px;
      height: 1px;
      display: block;
      flex-shrink: 0;
    }
  }

  @include min-width(large) {
    flex-direction: column;
    width: $progress-bar-width;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.ProgressSidebarLogo {
  height: $progress-bar-unstacked-height;
  width: $progress-bar-unstacked-height;
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin-right: 16px;

  @include min-width(large) {
    width: $progress-bar-width;
    height: $progress-bar-width;
    margin: 5px 0;
  }

  svg {
    display: block;
    height: auto;
    width: 75%;
    margin: auto;
  }
}

.ProgressSidebarMenu {
  list-style: none;
  display: flex;
  flex: 1;
  padding-right: math.div($progress-bar-unstacked-height, 4);
  position: relative;

  @include min-width(large) {
    flex-direction: column;
    width: 100%;
    padding-right: 0;
    padding-bottom: math.div($progress-bar-width, 4);
    padding-left: 16px;
    padding-right: 16px;
  }
}

.ProgressSidebarItem {
  justify-content: center;
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  width: 100%;

  & + & {
    margin-left: 20px;

    @include min-width(large) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.ProgressSidebarFuture,
.ProgressSidebarLink {
  color: var(--day-theme-color-text);
  display: block;
  border-radius: 36px;
  line-height: 20px;
  padding: 8px 4px 8px 22px;
  width: 104px;
  position: relative;
  text-align: center;
  margin: 0 auto;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    pointer-events: none;
  }

  &::before {
    background: $white;
  }

  svg {
    position: absolute;
    left: math.div(36px, 2);
    height: auto;
    height: 18px;
    width: 18px;
    top: 50%;
    margin: -9px;

    path {
      fill: currentColor;
    }
  }

  > span {
    position: relative;
  }
}

.ProgressSidebarFuture {
  opacity: 0.5;

  svg {
    height: 16px;
    width: 11px;
    // Lock is 11px wide so this is slightly off center, but
    // is not noticable in the build
    margin: -8px -5px -8px -6px;
  }
}

.ProgressSidebarLink {
  --circle-bg: #{rgba($toolbar-color, 0.1)};
  --circle-stroke: currentColor;

  text-decoration: none;
  transition: $transition;
  transition-property: background-color, color;

  &:hover,
  &:focus {
    color: var(--day-theme-color-dark);
  }
}

.ProgressSidebarLinkActive {
  &::after {
    background: none;
    box-shadow: $white 0 0 0 4px;
    opacity: 0.4;
  }
}

.ProgressSidebarLogout {
  cursor: pointer;
  font-size: 16px;
  font-weight: $font-weight-semibold;
  background: $white;
  color: var(--day-theme-color-text, $mid-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: 1px solid $lighter-gray;
  transition: color $transition;
  border-radius: $button-radius;
  @include min-width(large) {
    display: none;
  }

  &:hover,
  &:focus {
    color: var(--day-theme-color-dark, $mid-gray);
  }
}
