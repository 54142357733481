@import "../../constants";

$container-max-width-px: 600;

.SharedProgressPage {
  display: flex;
  flex: 1;
  padding-top: 72px;

  &THIRD_DAY {
    --day-theme-color-text: #{$day-theme-color-3-text};
    --day-theme-color-dark: #{$day-theme-color-3-dark};
    --day-theme-color: #{$day-theme-color-3};
    --day-theme-bg-opacity: #{$day-theme-color-3-bg-opacity};
  }

  &FIFTH_DAY {
    --day-theme-color-text: #{$day-theme-color-5-text};
    --day-theme-color-dark: #{$day-theme-color-5-dark};
    --day-theme-color: #{$day-theme-color-5};
    --day-theme-bg-opacity: #{$day-theme-color-5-bg-opacity};
  }
}

.PosterHeader {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  padding: 0 16px;
  min-height: 72px;
  background-color: rgba($white, 0.9);
}

.BackButton {
  position: absolute;
  @include max-width(450) {
    // Switch to normal flex positioning when the absolute positioning puts
    // the button too close to the title
    position: static;
  }
}

.WorksheetTitle {
  text-align: center;
  flex: 1;
}

.WorksheetContainer {
  width: 100%;
  padding-bottom: 100px;
  color: $white;
  display: flex;
  flex-flow: column;
  flex: 1;
  position: relative;

  h2 {
    overflow-wrap: break-word;
    text-transform: uppercase;
    margin-bottom: 32px;
    margin-top: 32px;
    padding: 0 32px;
    @include min-width($container-max-width-px) {
      text-align: center;
    }
  }
}

.WorksheetContainerBackgroundPattern {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: var(--day-theme-color-text);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url("../../images/poly-background.svg");
    mix-blend-mode: multiply;
    opacity: var(--day-theme-bg-opacity);
    background-position: center;
    background-size: cover;
  }
}

.WorksheetContainerOffset {
  margin: 0 auto;
  width: 100%;
  max-width: #{$container-max-width-px}px;
  padding-bottom: 64px;
  display: flex;
  flex-flow: column;
  position: relative;
  z-index: 3;
}

.ValuesContainer {
  display: flex;
  flex-flow: column;
  flex: 1;

  @include min-width($container-max-width-px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.Values {
  padding: 16px 32px;
  overflow: hidden;
  overflow-wrap: break-word;

  h3 {
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  ul {
    margin-top: 16px;

    li {
      margin-top: 8px;
      list-style: none;

      h4 {
        margin-bottom: 8px;
      }
    }
  }
}

.Logo {
  display: block;
  height: auto;
  width: 96px;
  margin: auto;
  margin-bottom: 32px;
}

.CopyLinkContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding: 18px;
  border-radius: 12px;
  max-width: calc(100vw - (18px * 2));
  width: fit-content;
  position: fixed;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white;

  a {
    text-align: center;
    color: var(--day-theme-color-text);
  }

  .CopyButton {
    white-space: nowrap;
  }
}

.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: white;
}
