@import "../../constants";

$poster-item-border-radius: 20px;
$poster-option-max-width: $input-wide-max-width * 0.5;

.PosterWelcomeRightPanel {
  // Make so the right panel has the same top margin of the left panel,
  // so things are aligned
  padding-top: $left-panel-padding;
}

.PosterWelcomeList {
  margin-top: 30px;
  display: grid;
  grid-gap: 24px;

  @media screen and (min-width: 36em) {
    grid-template-columns: 1fr 1fr;
  }
}

.PosterWelcomeListColumns {
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
}

.PosterWelcomeItem {
  background: var(--poster-choice-theme-color) center / cover no-repeat;
  cursor: pointer;
  border-radius: $poster-item-border-radius;
  position: relative;
  height: 106px;
  flex-shrink: 0;
  flex-grow: 1;

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border-radius: $poster-item-border-radius + 6px;
    pointer-events: none;
    border: 6px solid var(--poster-choice-theme-color);
    opacity: 0;
    transition: opacity $transition;
    z-index: 1;
  }

  &-blue,
  &-green,
  &-orange,
  &-red,
  &-purple {
    &::after {
      content: "";
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border-radius: $poster-item-border-radius + 6px;
      border: 6px solid white;
      pointer-events: none;
      background: transparent url("../../images/poly-background.svg")
        center/cover;
      mix-blend-mode: multiply;
      opacity: var(--day-theme-bg-opacity);
    }
  }

  &-blue {
    --poster-choice-theme-color: #{$day-theme-color-1-text};
  }

  &-green {
    --poster-choice-theme-color: #{$day-theme-color-2-text};
  }

  &-orange {
    --poster-choice-theme-color: #{$day-theme-color-3-text};
  }

  &-red {
    --poster-choice-theme-color: #{$day-theme-color-4-text};
  }

  &-purple {
    --poster-choice-theme-color: #{$day-theme-color-5-text};
  }

  &-simply-sage {
    --poster-choice-theme-color: #{$day-theme-color-1-text};
    background-image: url("../../images/theme/day-1-poster-choice.svg");
  }

  &-woodland-wave {
    --poster-choice-theme-color: #{$day-theme-color-2-text};
    background-image: url("../../images/theme/day-2-poster-choice@2x.jpg");
  }

  &-boldly-brushed {
    --poster-choice-theme-color: #{$day-theme-color-3-text};
    background-image: url("../../images/theme/day-3-poster-choice@2x.jpg");
  }

  &-striking-surreal {
    --poster-choice-theme-color: #{$day-theme-color-4-text};
    background-image: url("../../images/theme/day-4-poster-choice.svg");
  }

  &-creativity-cloud {
    --poster-choice-theme-color: #{$day-theme-color-5-text};
    background-image: url("../../images/theme/day-5-poster-choice@2x.jpg");
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 0.25;
    }
  }

  &.PosterWelcomeItemActive {
    &::before {
      opacity: 0.5;
    }
  }

  &.PosterWelcomeItemActive {
    cursor: default;
  }
}

.PosterWelcomeItemLabel {
  max-width: 50%;
  position: relative;
  z-index: 2;
}

.PosterWelcomeItemCheck {
  position: absolute;
  right: 16px;
  bottom: 16px;
  border-radius: 50%;
  z-index: 1;

  &::before {
    background: $white;
    content: "";
    position: absolute;
    border-radius: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: $transition;
    transition-property: background, opacity;
  }

  svg {
    display: block;
    height: 32px;
    width: 32px;
    position: relative;
  }

  .PosterWelcomeItemActive & {
    &::before {
      background: var(--poster-choice-theme-color);
      opacity: 0.5;
    }
  }
}

.PosterWelcomeCta {
  display: inline-flex;
  align-self: flex-start;
  margin-top: 32px;
}
