@import "../../constants";

.ButtonWithPrompt {
  font-size: 16px;
  margin-top: 64px;
}

.ButtonWithPromptWrapper {
  button {
    margin-right: 21px;
  }
}

.ButtonWithPromptKey {
  opacity: 0.8;
  font-weight: $font-weight-semibold;
  white-space: nowrap;
  line-height: 52px;
  display: inline-block;
  vertical-align: top;

  strong {
    font-weight: $font-weight-bold;
  }
}

.ButtonWithPromptAmend {
  opacity: 0.8;
  font-weight: $font-weight-semibold;
  max-width: 20em;
  margin-top: 16px;
  // A bug in Webkit removes the bottom margin entirely when this is in a
  // scrollable div with min-height set (Firefox renders it properly instead).
  // The padding instead is preserved, so we use that
  margin-bottom: 0;
  padding-bottom: 16px;
}
