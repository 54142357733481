@use "sass:math";
@import "../../constants";

.RightPanel {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: $white;
  padding: $right-panel-padding $mobile-panel-padding;
  color: $mid-gray;
  overflow-y: auto;

  @include min-width(large) {
    flex: auto;
    padding: $right-panel-padding;
    padding-top: 160px; // Height of the logo and the LeftPanel lead (date)
  }
}

.RightPanelSingle {
  @media screen and (min-height: #{math.div(760, 16) * 1em}) {
    // 112px is the average height of an input with a single line label (94px) with some extra
    // padding to opticall balance the weight of it
    padding-top: calc(50vh - 112px);
  }

  @include max-width(large) {
    padding-top: $right-panel-padding;
  }
}
