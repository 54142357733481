@use "sass:math";
@import "../../constants.scss";

$ordering-item-button-size: 24px;
$ordering-item-buttons-gutter: 8px;

.Container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.FieldContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  padding: 0 $mobile-panel-padding 20px;
  @include min-width(large) {
    padding: $left-panel-padding $right-panel-padding;
    mask: linear-gradient(
      rgba(0, 0, 0, 0),
      black $left-panel-padding,
      black calc(100% - #{$left-panel-padding}),
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::before {
    content: "";
  }
  &::after {
    content: "";
  }

  > * {
    // Define a min-height for the field wrapper that takes into account the
    // Submit button, so the field doesn't move vertically when it's
    // hidden/shown
    min-height: 300px;
  }
}

.StepsNav {
  $step-button-size: 36px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($step-button-size, 1fr));
  gap: 20px;
  padding: 0 $mobile-panel-padding;
  @include min-width(large) {
    padding: 15px $right-panel-padding 30px;
  }

  button {
    font-size: 16px;
    line-height: 1;
    color: $mid-gray;
    background: none;
    border-radius: 100%;
    border: solid 1px currentColor;
    width: $step-button-size;
    height: $step-button-size;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: $transition;
    transition-property: color, background-color, border-color;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.active {
      color: $white;
      background: var(--day-theme-color-text);
      border-color: transparent;
      cursor: default;
    }

    &.forbidNavigation {
      cursor: not-allowed;
    }

    svg path {
      fill: currentColor;
      stroke: currentColor;
    }
  }
}

.OrderingLabel {
  font-weight: $input-label-font-weight;
  font-size: $input-label-font-size;
  line-height: $input-label-line-height;
  margin-bottom: 16px;
  max-width: $input-max-width;
}

.OrderingItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  max-width: $input-max-width;
  position: relative;
}

.OrderingItemValue {
  flex: 1;
}

.OrderingItemInput {
  --input-padding-right: #{$ordering-item-button-size +
    $ordering-item-buttons-gutter};

  .OrderingItem & {
    margin-bottom: 0; // Wins the specificity award
  }

  .OrderingItemWithDelete & {
    --input-padding-right: #{(
        $ordering-item-button-size + $ordering-item-buttons-gutter
      ) * 2};
  }
}

.OrderingItemButtons {
  --ordering-item-buttons: 2;

  display: grid;
  grid-gap: 0 $ordering-item-buttons-gutter;
  position: absolute;
  right: 0;
  top: math.div($input-min-height, 2) - $input-bottom-border-width -
    $ordering-item-button-size;

  button {
    background: none;
    appearance: none;
    font-weight: $font-weight-bold;
    border: 0;
    color: rgba($mid-gray, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: $ordering-item-button-size;
    width: $ordering-item-button-size;
    outline: none;
    cursor: pointer;
    transition: color $transition;

    &:not([disabled]):hover,
    &:focus {
      color: var(--day-theme-color-text);
    }

    &:disabled {
      color: rgba($mid-gray, 0.4);
      cursor: not-allowed;
    }

    path {
      fill: currentColor;
    }

    &:nth-of-type(1) {
      grid-column: 1;
      grid-row: 1;
    }

    &:nth-of-type(2) {
      grid-column: 1;
      grid-row: 2;
    }

    &:nth-of-type(3) {
      align-self: center;
      grid-column: 2;
      grid-row: 1 / span 2;

      &:not([disabled]):hover,
      &:focus {
        color: $color-error;
      }
    }
  }
}

.SecondaryItemInput {
  // Specificity war win
  &#{&} {
    // Deliberately off full integer to show that there is something that can be scrolled
    --textarea-max-rows: 3.25;

    margin-bottom: 24px;
  }
}
