@import "../../constants.scss";

.Nav {
  padding: 20px;
  margin-bottom: 20px;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  font-weight: $font-weight-semibold;
  line-height: 2;

  > img {
    width: 114px;
    height: 123px;
  }

  > div {
    display: flex;
    align-items: flex-start;
  }
}

.Logout {
  margin-right: 30px;
  padding: 0 10px;
  background: none;
  border: none;
  color: currentColor;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: 2;
  cursor: pointer;
}
