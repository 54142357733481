@use "sass:math";
@import "../../constants";

.Menu {
  color: $toolbar-color;
  border: 1px solid currentColor;
  border-radius: 12px;
  position: absolute;
  top: 60px;
  width: 300px;
  overflow-y: auto;
  background: $white;
  text-align: left;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  @include min-width(large) {
    max-height: 70vh;
    max-width: 90vw;
    right: 0;
    left: unset;
    transform: unset;
  }
}

.MenuItem {
  display: block;
  &:not(:last-child) {
    border-bottom: 1px solid rgba($toolbar-color, 0.2);
  }

  .Container {
    padding: 20px;
  }
}

.MenuItemButton {
  width: 100%;
  appearance: none;
  background: none;
  border: none;
  font-size: inherit;
}

.MenuItemWithIcon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgba($toolbar-color, 0.1);
  }

  &.MenuItemDisabled {
    opacity: 0.5;
    cursor: default;
  }

  &.MenuItemDisabled:hover {
    background: inherit;
  }

  .ContainerWithIcon {
    margin-left: 12px;
  }
}

.Comment {
  position: relative;
  padding-left: 18px;
  cursor: pointer;

  .ReadStatus {
    display: flex;
    position: absolute;
    top: 3px;
    left: -7px;

    svg path,
    svg circle {
      fill: currentColor;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    h4 {
      font-size: 16px;
      font-weight: $font-weight-semibold;
    }

    button {
      background: none;
      font-size: 12px;
      color: #0e66f8;
      cursor: pointer;
      border: none;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  address {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    font-style: normal;
  }
}
