@use "sass:math";
@import "../../constants";

.Checkbox {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}

.CheckboxBox {
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  background: $color-default;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 52% 70%; // Optically balances the checkbox
  margin-top: -2px;

  input:checked ~ & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  }

  input:focus ~ & {
    outline: white auto 1px;
    outline-offset: 2px;
  }
}

.CheckboxText {
  margin-left: math.div($base-spacing-unit, 2);
  user-select: none;

  a {
    color: currentColor;
    font-weight: $font-weight-semibold;
  }
}
