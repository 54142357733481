@import "../../constants.scss";

@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Spinner {
  opacity: 0;
  transition: opacity $transition-fast;
  position: relative;
  width: 64px;

  &::before {
    content: "";
    padding-top: 100%;
    display: block;
  }

  svg {
    animation: rotate-loader 1s infinite linear;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  path {
    stroke: $black;
    stroke-width: var(--stroke-width);
  }

  rect {
    fill: $white;
  }
}

.SpinnerInverted {
  path {
    stroke: $white;
  }

  rect {
    fill: $black;
  }
}

.SpinnerActive {
  opacity: 1;
}

.SpinnerAbsolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
