@import "./constants.scss";

@mixin font-face($font-name, $font-prefix, $font-weight, $font-style) {
  $font-suffix: if($font-style == italic, #{$font-weight}i, $font-weight);

  @font-face {
    font-family: $font-name;
    font-weight: $font-weight;
    font-style: $font-style;
    src: url("./fonts/#{$font-prefix}-#{$font-suffix}.woff2") format("woff2"),
      url("./fonts/#{$font-prefix}-#{$font-suffix}.woff") format("woff");
    display: swap;
  }
}

@include font-face("DWD Body", "pn", $font-weight-regular, normal);
@include font-face("DWD Body", "pn", $font-weight-regular, italic);

@include font-face("DWD Body", "pn", $font-weight-medium, normal);
@include font-face("DWD Body", "pn", $font-weight-medium, italic);

@include font-face("DWD Body", "pn", $font-weight-semibold, normal);
@include font-face("DWD Body", "pn", $font-weight-semibold, italic);

@include font-face("DWD Body", "pn", $font-weight-bold, normal);
@include font-face("DWD Body", "pn", $font-weight-bold, italic);

@include font-face("RRI Heading", "RRI-Heading", 600, normal);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: calc(0.56rem + 0.5vw);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
}

body {
  font-family: $base-font-family;
  font-size: 16px;
  line-height: $default-line-height;
}

#root,
html,
body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@include min-width(large) {
  #root,
  html,
  body {
    height: 100%;
  }
}

p {
  margin-bottom: $base-spacing-unit;
}

// Helpers
.mt {
  margin-top: $base-spacing-unit;
}
.mb {
  margin-bottom: $base-spacing-unit;
}
.ml {
  margin-left: $base-spacing-unit;
}
.mr {
  margin-right: $base-spacing-unit;
}

.pt {
  padding-top: $base-spacing-unit;
}
.pb {
  padding-bottom: $base-spacing-unit;
}
.pl {
  padding-left: $base-spacing-unit;
}
.pr {
  padding-right: $base-spacing-unit;
}

pre {
  white-space: pre-wrap;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
