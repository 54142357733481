@import "../Admin/index.module.scss";

$content-padding-left: 95px;
$content-padding-right: 40px;
$progress-column-width: 210px;
$sections-spacing: 30px;
$titles-spacing: 15px;
$items-spacing: 10px;

.TitleContainer {
  margin-bottom: 20px;
  align-items: center;
}

.ClientTitle {
  padding-left: $content-padding-left;
  position: relative;

  a {
    position: absolute;
    top: 3px;
    left: 20px;
    padding: 10px;
    color: inherit;

    svg path {
      fill: currentColor;
    }
  }

  h1 {
    margin: 0;

    .FlaggedMark {
      $size: 36px;
      width: $size;
      height: $size;
      line-height: $size;
      font-size: 29px;
      transform: translateY(-2px);
    }
  }

  div {
    font-size: 22px;
    line-height: 2;
    font-weight: $font-weight-semibold;

    span {
      opacity: 0.6;
    }
  }
}

.ClientNotes {
  display: flex;
  flex-direction: column;
  padding-left: $content-padding-left;
  padding-right: $content-padding-right;
  margin-bottom: 60px;

  textarea {
    padding: 10px 15px;
    font: inherit;
    color: white;
    border: none;
    border-left: 6px solid $admin-accent;
    border-radius: 10px;
    background: rgba(white, 0.05);
    resize: vertical;

    &::placeholder {
      font-style: italic;
      color: rgba(white, 0.5);
    }
  }
}

.ButtonWithBorder {
  color: white;
  background: none;
  border: solid 1px currentColor;
  border-radius: 6px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 7px;

    path {
      fill: currentColor;
    }
  }
}

.Controls {
  display: flex;
  align-items: center;
  margin-right: $content-padding-right;

  .ClientFlagged {
    display: flex;
    align-items: baseline;
    font-size: 20px;
    font-weight: $font-weight-semibold;
    margin-right: 30px;
    margin-left: 30px;

    .Switch {
      margin-left: 10px;
      display: inline-block;
    }
  }
}

.Divider {
  border: none;
  background: rgba(white, 0.2);
  height: 1px;
  margin-left: $content-padding-left + $progress-column-width;
  overflow: visible;
}

.Numbered {
  $disc-size: 18px;
  $disc-margin: 10px;
  position: relative;
  padding-left: $disc-size + $disc-margin;
  padding-bottom: $items-spacing;

  &:last-child {
    padding-bottom: 0;
  }

  &::before {
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    width: $disc-size;
    height: $disc-size;
    border-radius: 100%;
    background: $admin-accent;
    color: $admin-background;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: $disc-size;
    text-align: center;
  }
}

.Missing {
  opacity: 0.5;
  font-style: italic;
}

.Day {
  margin: 40px $content-padding-right 40px $content-padding-left;
  font-size: 17px;
  position: relative;
}

.DayProgress {
  position: absolute;
  top: 0;
  left: 0;
}

.DayOverview {
  padding-left: $progress-column-width;

  h3 {
    font-size: inherit;
    font-weight: $font-weight-bold;
    margin-bottom: $titles-spacing;
  }

  p {
    margin-bottom: $sections-spacing;
  }

  ol {
    list-style: none;

    li {
      @extend .Numbered;
      counter-increment: item-index;

      &::before {
        content: counter(item-index);
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: $sections-spacing;
    border-collapse: collapse;

    tbody tr {
      counter-increment: row-index;

      &:last-child td {
        // Remove bottom padding from last line
        padding-bottom: 0;
      }
    }

    td {
      padding-bottom: $items-spacing;

      &:first-child {
        @extend .Numbered;

        &::before {
          content: counter(row-index);
        }
      }
    }

    th {
      font-weight: $font-weight-bold;
      padding-bottom: $titles-spacing;
    }

    td,
    th {
      text-align: left;
      vertical-align: top;

      &:first-child {
        width: 37%;
        padding-right: 6%;
      }
    }
  }
}

.Day2 {
  .DayOverview {
    display: grid;
    grid-template-columns: 37% auto;
  }

  .PowerVirtues {
    padding-right: 6%;
  }

  .PrimaryQuestion {
    flex-basis: 2;
    > div {
      display: grid;
      grid-template-columns: min-content auto;
      align-items: baseline;
      gap: $items-spacing 15px;

      h4 {
        opacity: 0.5;
        font-weight: $font-weight-bold;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.Poster {
  padding-left: $progress-column-width;
  margin: 40px $content-padding-right 40px $content-padding-left;

  button {
    margin-bottom: 15px;
  }
}
