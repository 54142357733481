@import "../../constants";

.Home {
  background: $day-theme-default;
}

.HomeContent {
  background: linear-gradient(rgba($black, 0.7), rgba($black, 0.7)),
    $black url("../../images/home-background.png") center / cover no-repeat;
  position: relative;
  color: $white;
  padding-top: $right-panel-padding;
}

.HomeCopy {
  font-size: 24px;
  margin: auto;

  @include min-width(large) {
    max-width: 32em;
  }

  h3 {
    font-weight: $font-weight-regular;
    font-size: 30px;
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 1em;
    line-height: 1.5;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  a {
    margin-top: 64px;
  }
}
